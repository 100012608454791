import { ElementType } from "@/constants/tagular/main";
import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { hyphenateForTagular } from "@/utils/tagular/helpers";
import Image from "next/image";
import { ImagesListItemProps } from "./types";

export default function FilterLink({
  label,
  query,
  src,
  index,
}: ImagesListItemProps & { index: number }) {
  const params = new URLSearchParams({ subject: query });

  const webElement: ElementClicked["webElement"] = {
    elementType: ElementType.Filter,
    htmlId: "image-tile",
    location: "popular-subjects",
    text: label,
    position: (index + 1).toString(),
    ...(label && {
      name: hyphenateForTagular(label),
    }),
  };

  const { elementClicked, elementViewed } = useElementEvents({
    webElement,
  });

  const { inView, ref } = useInView();

  useEffect(() => {
    if (inView) {
      elementViewed();
    }
  }, [inView, elementViewed]);

  const handleClick = () => {
    elementClicked({ outboundUrl: params.toString() });
  };

  return (
    <div key={label} className="w-52">
      <a
        ref={ref}
        onClick={handleClick}
        className="flex gap-3"
        key={label}
        href={`/search?${params.toString()}`}
      >
        <Image
          src={src}
          alt={label}
          width={80}
          height={80}
          layout="fixed"
          className="w-20 h-20"
        />
        <span className="font-semibold text-wrap text-balance place-content-center grow">
          {label}
        </span>
      </a>
    </div>
  );
}
